export const config = {
  TITLE: 'Sistem Pentadbiran PTRS',
  SUBTITLE: 'Sistem Pentadbiran PTRS',
  DESCRIPTION:
    'Program Tuisyen Rakyat ialah program khusus yang dijalankan bagi meningkatkan, memenuhi dan merealisasikan agenda bagi memastikan kebajikan rakyat, kualiti hidup rakyat, memantapkan ekonomi rakyat menyempurnakan definisi pendidikan kepada 5M (membaca, menulis, mengira, memikir dan mengamal) adalah antara fokus utama Kerajaan Negeri Selangor.',
  // SERVER_ENDPOINT: ' https://apiptrs.cloudhub.my/api',
  SERVER_ENDPOINT: 'https://apiadmin.eptrs.my/api',
  //CLIENT_ENDPOINT: 'https://dev.admin.eptrs.my',
  CLIENT_ENDPOINT: 'https://admin.eptrs.my',
  DEFAULT_LANGUAGE: 'my',
  DEFAULT_THEME: 'light',
  ROLES: {
    SUPER_ADMIN: 'super-admin',
    PPD_FACILITATOR: 'ppd-facilitator',
    SCHOOL_FACILITATOR: 'school-facilitator',
    SCHOOL_PRINCIPAL: 'school-principals',
    SCHOOL_CLAIM_VERIFIER: 'claim-verifier',
    BPSM_MANAGEMENT: 'bpsm-management',
    TEACHER: 'teacher',
    STUDENT: 'student',
  },
};
