import { config } from '../app.config';
export enum app {
  CHANGE_GRID_VIEW = 'CHANGE_GRID_VIEW',
  CHANGE_THEME = 'CHANGE_THEME',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  SET_KEYWORD_MAIN_SEARCH = 'SET_KEYWORD_MAIN_SEARCH',
  SNACKBAR_MESSAGE = 'SNACKBAR_MESSAGE',
}

export enum drawer {
  DRAWER_TOOGLE = 'DRAWER_TOOGLE',
}

export enum user {
  USER_LOGIN_STATUS = 'USER_LOGIN_STATUS',
  USER_LOGOUT = 'USER_LOGOUT',
  USER_INIT = 'USER_INIT',
  USER_INIT_ASYNC = 'USER_INIT_ASYNC',
  GET_USER_SOCKET = 'GET_USER_SOCKET',
  GET_SEARCH_SUGGESTION_LIST = 'GET_SEARCH_SUGGESTION_LIST',
  GET_SEARCH_SUGGESTION_LIST_ASYNC = 'GET_SEARCH_SUGGESTION_LIST_ASYNC',
  GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST',
  GET_NOTIFICATION_LIST_ASYNC = 'GET_NOTIFICATION_LIST_ASYNC',
  SET_IS_ONLINE = 'SET_IS_ONLINE',
}
