import { all } from 'redux-saga/effects';

import { watchUserSagas } from './user-saga';

import { watchStudentCertSagas } from '../PAGES/user/certificate/store/saga';

import { watchUserSagas as watchUserViewOnlySagas } from '../PAGES/user-view-only/store/saga';

export default function* rootSaga() {
  yield all([
    watchUserSagas(),

    watchUserViewOnlySagas(),

    watchStudentCertSagas(),
  ]);
}
