import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import packageJSON from '../package.json';
const allowedLanguages = ['en', 'my'];

const defaultLng = 'my';
let lng = defaultLng;

const Languages = ['en', 'my'];

const storageLanguage = localStorage.getItem('language');
if (
  storageLanguage &&
  allowedLanguages.indexOf(storageLanguage) > -1 &&
  storageLanguage
) {
  lng = storageLanguage;
}

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'my',
    ...lng,
    debug: false,
    // whitelist: Languages,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    backend: {
      loadPath: '/assets/translations/{{lng}}.json?v=' + packageJSON.version,
      allowMultiLoading: true,
    },
    // react: {
    //   wait: true,
    //   useSuspense: true,
    // },
  });

export default i18n;
