import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import DeviceDetect from './device-detect';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../../COMPONENTS/loading-spinnar';
import {
  getLanguage,
  getTheme,
  getUserSession,
} from '../../SERVICES/user-service';
import { app, user } from '../../STORES/types';
import SnackbarMessage from './snackbar-message';
import WithWidthRendering from './with-width-rendering';

const ModalUpdatePassword = lazy(
  () => import('../modal-alert-initial-login/index')
);
const user_session: any = getUserSession();
const InitialRendering = (props: any) => {
  const dispatch = useDispatch();
  const redux = useSelector((state: any) => state.redux);

  React.useEffect(() => {
    const theme: any = getTheme();
    const language: any = getLanguage();

    dispatch({
      type: app.CHANGE_THEME,
      theme: theme,
    });
    dispatch({
      type: app.CHANGE_LANGUAGE,
      language: language,
    });

    if (user_session?.token) {
      dispatch({
        type: user.USER_INIT,
      });
      dispatch({
        type: user.USER_LOGIN_STATUS,
        is_login: true,
      });
    } else {
      dispatch({
        type: user.USER_INIT_ASYNC,
        data: {
          is_loading: false,
          id: 1,
          avatar: null,
          email: `${language == 'my' ? 'pelawat' : 'guest'}@gmail.com`,
          first_name: `${language == 'my' ? 'Pelawat' : 'Guest'}`,

          last_name: `${language == 'my' ? 'Pelawat' : 'Guest'}`,
          bio: null,
          role: null,
        },
      });
    }
  }, []);

  const [firstTimeLogin, setFirstTimeLogin] = React.useState(false);
  const userLogin = redux.user?.user;
  React.useEffect(() => {
    if (userLogin.is_loading == false) {
      if (userLogin.first_time_login) {
        setFirstTimeLogin(true);
      }
    }
  }, [userLogin.is_loading]);

  const handleCloseModal = () => {
    setFirstTimeLogin(false);
  };

  return (
    <div>
      {firstTimeLogin ? (
        <Suspense
          fallback={
            <div className='loading-wrapper'>
              <LoadingSpinner />{' '}
              <h3 style={{ marginLeft: '10px' }}>Loading...</h3>
            </div>
          }
        >
          <ModalUpdatePassword handleCloseModal={handleCloseModal} />
        </Suspense>
      ) : null}
    </div>
  );
};

const MainLayout = (props: any) => {
  const redux = useSelector((state: any) => state.redux);
  const location = useLocation();
  const DetectDarkMode = () => {
    const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
    const dispatch = useDispatch();
    React.useEffect(() => {
      if (isDarkModeEnabled) {
        dispatch({
          type: app.CHANGE_THEME,
          theme: 'dark',
        });
      }
    }, []);
    return null;
  };
  return (
    <div
      className={`app-view--wrapper ${redux.app.current_grid} ${redux.app.default_theme}`}
    >
      {/* <DeviceDetect /> */}
      <DetectDarkMode />
      <WithWidthRendering />
      <InitialRendering />
      <SnackbarMessage />

      {/* <Helmet>
                <title>{redux.app.title}</title>
                <link
                    rel='canonical'
                    href={`${config.CLIENT_ENDPOINT}${location.pathname}`}
                />
                <meta charSet='utf-8' />
                <meta name='description' content={redux.app.description} />
            </Helmet> */}

      {props.children}
    </div>
  );
};

export default MainLayout;
