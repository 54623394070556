import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MainRouter from '../ROUTERS/index';

import Theme from './App-theme';
import Themev5 from './App-theme-v5';
function App(props: any) {
  return (
    <Themev5>
      <Theme>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MainRouter />
        </MuiPickersUtilsProvider>
      </Theme>
    </Themev5>
  );
}

export default App;
