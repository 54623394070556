const initialState = {
  levels: {
    data: [
      {
        value: 'spm',
        label: 'SPM',
      },
      {
        value: 'stpm',
        label: 'STPM',
      },
    ],
  },
  subjects: {
    data: [
      {
        label: 'Bahasa Melayu',
        value: 'bm',
      },
      {
        label: (t?: any) => t('filter.bi'),
        value: 'bi',
      },
      {
        label: (t?: any) => t('filter.science'),
        value: 'science',
      },
      {
        label: (t?: any) => t('filter.math'),
        value: 'math',
      },
    ],
  },
  upload_date: {
    data: [
      {
        label: (t?: any) => t('filter.last_hour'),
        value: 'lhr',
      },
      {
        label: (t?: any) => t('filter.today'),
        value: 'tdy',
      },
      {
        label: (t?: any) => t('filter.this_week'),
        value: 'twk',
      },
      {
        label: (t?: any) => t('filter.this_month'),
        value: 'tmh',
      },
      {
        label: (t?: any) => t('filter.this_year'),
        value: 'tyr',
      },
    ],
  },
  durations: {
    data: [
      {
        label: (t?: any) => t('filter.short'),
        value: 'shr',
      },
      {
        label: (t?: any) => t('filter.long'),
        value: 'log',
      },
    ],
  },
  watching_progress: {
    data: [
      {
        label: (t?: any) => t('filter.not_started'),
        value: 'not_started',
      },
      {
        label: (t?: any) => t('filter.in_progress'),
        value: 'in_progress',
      },
      {
        label: (t?: any) => t('filter.completed'),
        value: 'completed',
      },
    ],
  },
  sort_by_users: {
    data: [
      {
        label: (t?: any) => t('filter.name_a_to_z'),
        value: 'atz',
      },
      {
        label: (t?: any) => t('filter.name_z_to_a'),
        value: 'zta',
      },
      {
        label: (t?: any) => t('filter.register_date'),
        value: 'desc',
      },
      // {
      //     label: (t?: any) => t('filter.relevance'),
      //     value: 'rlc',
      // },
      // {
      //     label: (t?: any) => t('filter.upload_date'),
      //     value: 'upd',
      // },
      // {
      //     label: (t?: any) => t('filter.view_count'),
      //     value: 'vct',
      // },
      // {
      //     label: (t?: any) => t('filter.rating'),
      //     value: 'rtg',
      // },
    ],
  },
  sort_by_for_claim: {
    data: [
      {
        label: (t?: any) => t('filter.name_a_to_z'),
        value: 'atz',
      },
      {
        label: (t?: any) => t('filter.name_z_to_a'),
        value: 'zta',
      },
      {
        label: (t?: any) => t('th.claim_date'),
        value: 'desc',
      },
    ],
  },
  educator_roles: {
    data: [
      {
        label: (t?: any) => t('submodule.teachers'),
        value: 7,
        slug: 'teacher',
      },
      {
        label: (t?: any) => t('submodule.ppd-facilitators'),
        value: 4,
        slug: 'ppd-facilitator',
      },
      {
        label: (t?: any) => t('submodule.school-facilitators'),
        value: 5,
        slug: 'school-facilitator',
      },
      {
        label: (t?: any) => t('submodule.headmaster'),
        value: 6,
        slug: 'school-principals',
      },
    ],
  },
  users_roles: {
    data: [
      {
        label: (t?: any) => t('submodule.teachers'),
        value: 7,
        slug: 'teacher',
      },
      {
        label: (t?: any) => t('submodule.ppd-facilitators'),
        value: 4,
        slug: 'ppd-facilitator',
      },
      {
        label: (t?: any) => t('submodule.school-facilitators'),
        value: 5,
        slug: 'school-facilitator',
      },
      {
        label: (t?: any) => t('submodule.headmaster'),
        value: 6,
        slug: 'school-principals',
      },
      {
        label: (t?: any) => t('role.bpsm'),
        value: 3,
        slug: 'bpsm-management',
      },
      {
        label: (t?: any) => t('role.superadmin'),
        value: 1,
        slug: 'super-admin',
      },
    ],
  },
  management_roles: {
    data: [
      {
        label: (t?: any) => t('role.bpsm'),
        value: 3,
        slug: 'bpsm-management',
      },
      {
        label: (t?: any) => t('role.superadmin'),
        value: 1,
        slug: 'super-admin',
      },
    ],
  },
  student_packages: {
    data: [
      {
        label: 'Pakej A : Tuisyen + Modul',
        value: 1,
      },
      {
        label: 'Pakej B : Modul sahaja',
        value: 2,
      },
    ],
  },
  user_status: {
    data: [
      {
        label: (t?: any) => t('label.active'),
        value: 1,
      },
      {
        label: (t?: any) => t('label.inactive'),
        value: 0,
      },
    ],
  },
  voucher_dates: {
    data: [
      {
        label: (t?: any) => t('th.claim_date'),
        value: 'claim-date',
      },
      {
        label: (t?: any) => t('th.approve_date'),
        value: 'approved-date',
      },
      {
        label: (t?: any) => t('th.voucher_issued_date'),
        value: 'voucher_issued_date',
      },
    ],
  },
  hours: {
    data: [
      {
        label: (t?: any) => t('1_hour'),
        value: 1,
      },
      {
        label: (t?: any) => t('2_hours'),
        value: 2,
      },
    ],
  },

  school_phases: {
    data: [
      {
        label: 'Rendah',
        value: 'Rendah',
      },
      {
        label: 'Menengah',
        value: 'Menengah',
      },
    ],
  },
  school_types: {
    data: [
      {
        label: 'SK',
        value: 'SK',
      },
      {
        label: 'SMK',
        value: 'SMK',
      },
      {
        label: 'SJKC',
        value: 'SJKC',
      },
      {
        label: 'SMKA',
        value: 'SMKA',
      },
    ],
  },
  months: {
    data: [
      {
        value: 1,
        label: 'Januari',
      },
      {
        value: 2,
        label: 'Februari',
      },
      {
        value: 3,
        label: 'Mac',
      },
      {
        value: 4,
        label: 'April',
      },
      {
        value: 5,
        label: 'Mei',
      },
      {
        value: 6,
        label: 'Jun',
      },
      {
        value: 7,
        label: 'Julai',
      },
      {
        value: 8,
        label: 'Ogos',
      },
      {
        value: 9,
        label: 'September',
      },
      {
        value: 10,
        label: 'Oktober',
      },
      {
        value: 11,
        label: 'November',
      },
      {
        value: 12,
        label: 'Disember',
      },
    ],
  },
  bank_names: {
    data: [
      {
        label: 'Maybank',
        value: '27',
      },
      {
        label: 'CIMB Bank',
        value: '35',
      },
      {
        label: 'RHB Bank',
        value: '18',
      },
      {
        label: 'Bank Islam',
        value: '45',
      },
      {
        label: 'Bank Muamalat',
        value: '41',
      },
      {
        label: 'Bank Rakyat',
        value: '02',
      },
      {
        label: 'Bank Simpanan Nasional',
        value: '10',
      },
      {
        label: 'Citibank',
        value: '17',
      },
      {
        label: 'Hong Leong Bank',
        value: '24',
      },
      {
        label: 'HSBC Bank',
        value: '22',
      },
      {
        label: 'OCBC Bank',
        value: '29',
      },
      {
        label: 'Public Bank',
        value: '33',
      },
      {
        label: 'Affin Bank',
        value: '32',
      },
      {
        label: 'AmBank',
        value: '08',
      },
      {
        label: 'Agro Bank',
        value: '49',
      },
      {
        label: 'Alliance Bank',
        value: '12',
      },
      {
        label: 'Al-Rajhi Bank',
        value: '53',
      },
      {
        label: 'Bank of China',
        value: '42',
      },
      {
        label: 'Bank of America',
        value: '07',
      },
      {
        label: 'Bank of Tokyo-Mitsubishi UFJ',
        value: '52',
      },
      {
        label: 'BNP Paribas',
        value: '60',
      },
      {
        label: 'Deutsche Bank',
        value: '19',
      },
      {
        label: 'Industrial & Commercial Bank of China',
        value: '59',
      },
      {
        label: 'JP Morgan Chase Bank',
        value: '48',
      },
      {
        label: 'Kuwait Finance House',
        value: '47',
      },
      {
        label: 'Mizuho Bank',
        value: '73',
      },
      {
        label: 'Standard Chartered Bank',
        value: '14',
      },
      {
        label: 'Sumitomo Mitsui Banking Corporation',
        value: '51',
      },
      {
        label: 'The Royal Bank of Scotland',
        value: '46',
      },
      {
        label: 'United Overseas Bank',
        value: '26',
      },
    ],
  },
  allowance_types: {
    data: [
      {
        label: (t?: any) => t('label.facilitator_allowance'),
        value: 'elaun_penyelaras',
      },
      {
        label: (t?: any) => t('label.meal_allowance'),
        value: 'elaun_makan',
      },
    ],
  },
  inquiry_options: {
    data: [
      {
        label: 'ID Guru, Penyelaras & Pengetua',
        value: '1',
      },
      {
        label: 'Pelajar PTRS',
        value: '2',
      },
      {
        label: 'Tuntutan',
        value: '3',
      },
    ],
  },
  scores: {
    data: [
      {
        label: 'A+',
        value: 'A+',
      },
      {
        label: 'A',
        value: 'A',
      },
      {
        label: 'A-',
        value: 'A-',
      },
      {
        label: 'B+',
        value: 'B+',
      },
      {
        label: 'B',
        value: 'B',
      },
      {
        label: 'C+',
        value: 'C+',
      },
      {
        label: 'C',
        value: 'C',
      },
      {
        label: 'D',
        value: 'D',
      },
      {
        label: 'E',
        value: 'E',
      },
      {
        label: 'G',
        value: 'G',
      },
      {
        label: 'TH',
        value: 'TH',
      },
    ],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default todos;
