import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../CONTAINERS/layouts/index-wrapper';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import '../i18n';
import { CheckPrivateRoute } from './check-private-route';
import { PrivateRoute } from './private-route';

const Welcome = lazy(() => import('../PAGES/welcome/index'));
const Dashboard = lazy(() => import('../PAGES/dashboard/index'));
const TeacherFacilitator = lazy(
  () => import('../PAGES/teacher-facilitator/index')
);
const AdminUsers = lazy(() => import('../PAGES/users/index'));
const Students = lazy(() => import('../PAGES/students/index'));
const AddStudentBulk = lazy(
  () => import('../PAGES/students/add-students-bulk/index')
);
const AddAchievementBulk = lazy(
  () => import('../PAGES/students/add-achievements-bulk/index')
);
const Schools = lazy(() => import('../PAGES/schools/index'));
const Classes = lazy(() => import('../PAGES/classes/index'));
const Claim_details = lazy(() => import('../PAGES/claim-details/index'));
const Class_details = lazy(() => import('../PAGES/class-details/index'));
const AddStudentToClass = lazy(
  () => import('../PAGES/class-details/add-student/index')
);
const Claims = lazy(() => import('../PAGES/claims/index'));
const Vouchers = lazy(() => import('../PAGES/vouchers/index'));
const Approval = lazy(() => import('../PAGES/approval/index'));
const School_details = lazy(() => import('../PAGES/school-details/index'));

const Login = lazy(() => import('../PAGES/login/index'));
const ForgotPassword = lazy(() => import('../PAGES/forgot-password/index'));
const ResetPassword = lazy(() => import('../PAGES/reset-password/index'));
const Register = lazy(() => import('../PAGES/register/index'));
const Logout = lazy(() => import('../PAGES/logout/index'));

const NotFoundPage = lazy(() => import('../PAGES/404/index'));

const VerifyEmail = lazy(() => import('../PAGES/verify-email/index'));
const User = lazy(() => import('../PAGES/user/index'));
const AdminUserView = lazy(() => import('../PAGES/admin_user-view/index'));

const Voucher_details = lazy(() => import('../PAGES/voucher-details/index'));

const ApprovalAllDetails = lazy(
  () => import('../PAGES/approval-all-details/index')
);

const ApprovalSchoolDetails = lazy(
  () => import('../PAGES/approval-school-details/index')
);

const ApprovalBPSMDetails = lazy(
  () => import('../PAGES/approval-bpsm-details/index')
);

const ApprovalVoucherDetails = lazy(
  () => import('../PAGES/approval-voucher-details/index')
);

const NewClaimsToPrincipalApproval = lazy(
  () => import('../PAGES/approval-school-details/new-claims/index')
);

function MainRouter() {
  return (
    <MainWrapper>
      <Suspense
        fallback={
          <div className='loading-wrapper'>
            <Grid item xs={8} sm={5}>
              <Skeleton height={200} />
              <Skeleton height={50} />
              <Skeleton height={50} animation={false} />
              <Skeleton height={50} animation='wave' />
            </Grid>
          </div>
        }
      >
        <Switch>
          <PrivateRoute exact path='/' component={Welcome} />
          {/* <PrivateRoute exact path='/welcome' component={Welcome} /> */}
          <PrivateRoute
            exact
            path='/educator/:view?/:keyword?'
            component={TeacherFacilitator}
          />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute
            exact
            path='/claims/:view?/:keyword?'
            component={Claims}
          />
          <PrivateRoute
            exact
            path='/vouchers/:view?/:keyword?'
            component={Vouchers}
          />
          <PrivateRoute
            exact
            path='/approval/:view?/:keyword?'
            component={Approval}
          />
          <PrivateRoute exact path='/schools/:keyword?' component={Schools} />
          <PrivateRoute exact path='/classes/:keyword?' component={Classes} />
          <PrivateRoute
            exact
            path='/school/:view/:school_slug'
            component={School_details}
          />
          <PrivateRoute
            exact
            path='/voucher/:view/:user_slug/:keyword?'
            component={Voucher_details}
          />
          <PrivateRoute
            exact
            path='/school-approval/:view/:school_slug/:year/:keyword?'
            component={ApprovalSchoolDetails}
          />
          <PrivateRoute
            exact
            path='/all-approval/:view/:school_slug/:year/:keyword?'
            component={ApprovalAllDetails}
          />
          <PrivateRoute
            exact
            path='/bpsm-approval/:view/:school_slug/:year/:keyword?'
            component={ApprovalBPSMDetails}
          />
          <PrivateRoute
            exact
            path='/voucher-approval/:view/:school_slug/:year/:keyword?'
            component={ApprovalVoucherDetails}
          />
          <PrivateRoute
            exact
            path='/new-claims/add-to-principal-approval/:school_slug/:year/:keyword?'
            component={NewClaimsToPrincipalApproval}
          />
          <PrivateRoute
            exact
            path='/claim/:view/:claim_no'
            component={Claim_details}
          />
          <PrivateRoute
            exact
            path='/class/:view/:class_no'
            component={Class_details}
          />
          <PrivateRoute
            exact
            path='/add-student/class/:class_no'
            component={AddStudentToClass}
          />
          <PrivateRoute
            exact
            path='/students/:view?/:keyword?'
            component={Students}
          />
          <PrivateRoute
            exact
            path='/students-add-bulk'
            component={AddStudentBulk}
          />
          <PrivateRoute
            exact
            path='/achievements-add-bulk'
            component={AddAchievementBulk}
          />
          <PrivateRoute
            exact
            path='/users/:view?/:keyword?'
            component={AdminUsers}
          />
          <PrivateRoute exact path='/logout' component={Logout} />

          <CheckPrivateRoute exact path='/login' component={Login} />
          <CheckPrivateRoute exact path='/register' component={Register} />
          <CheckPrivateRoute
            exact
            path='/forgot-password'
            component={ForgotPassword}
          />
          <CheckPrivateRoute
            exact
            path='/reset-password/:token'
            component={ResetPassword}
          />
          <PrivateRoute
            exact
            path='/user/:role_slug/account/:view/:user_slug?'
            component={User}
          />
          <PrivateRoute
            exact
            path='/user-view/:role_slug/account/:view/:user_slug'
            component={AdminUserView}
          />

          <Route exact path='/verify-email/:email' component={VerifyEmail} />
          <Route exact path='/404' component={NotFoundPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </MainWrapper>
  );
}

export default MainRouter;
